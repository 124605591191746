import DrawerMenuLayout from '@arrive/layout/header/DrawerMenuLayout';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ViewListIcon from '@mui/icons-material/ViewList';

import {
  AutoAwesomeMotion as PickIcon,
  Checkroom as CheckinIcon,
  CreateNewFolder as ReceivingIcon,
  LocalShipping as ShipIcon,
  PhotoSizeSelectSmall as ContainerizeIcon,
  SelectAll as PackIcon,
  ShoppingCart as ShoppingCartIcon,
  Sort as PresortIcon,
  Undo as TakebackIcon,
  ShoppingCartCheckout as InboundShoppingCartIcons,
  Print as ReturnLabelIcon
} from '@mui/icons-material';
import { lazy, Suspense } from 'react';
import { Loader } from '@arrive/components/loader';

export type Route = {
  path: string;
  menuName?: string;
  element: JSX.Element;
  icon: JSX.Element;
  description?: string;
  reload?: boolean;
};

const Main = lazy(() => import('../pages/MainPage'));
const Orders = lazy(() => import('../pages/OrdersPage'));
const InboudOrder = lazy(() => import('../pages/InboundOrderPage'));
const Units = lazy(() => import('../pages/UnitsPage'));
const Receiving = lazy(() => import('../pages/ReceivePage'));
const Checkin = lazy(() => import('../pages/CheckinPage'));
const Presort = lazy(() => import('../pages/PresortPage'));
const Pack = lazy(() => import('../pages/PackPage'));
const Pick = lazy(() => import('../pages/PickPage'));
const Move = lazy(() => import('../pages/MovePage'));
const Ship = lazy(() => import('../pages/ShipPage'));
const Containerize = lazy(() => import('../pages/ContainerizePage'));
const Takeback = lazy(() =>
  import('../pages/TakebackPage').then((mod) => ({ default: mod.TakebackPage }))
);
const ReturnLabel = lazy(() =>
  import('../pages/ReturnLabelPage').then((mod) => ({ default: mod.ReturnLabelPage }))
);
const GraphiQl = lazy(() =>
  import('@arrive/features/graphiql/GraphiQl').then(({ GraphiQl }) => ({ default: GraphiQl }))
);

const menuRoutes: Route[] = [
  {
    path: '/',
    element: (
      <Suspense fallback={<Loader />}>
        <Main />
      </Suspense>
    ),
    icon: <AssignmentReturnIcon />
  },
  {
    path: '/receive',
    menuName: 'Receiving',
    element: (
      <Suspense fallback={<Loader />}>
        <Receiving />
      </Suspense>
    ),
    icon: <ReceivingIcon width={24} height={24} />
  },
  {
    path: '/presort',
    menuName: 'Pre-Sort',
    element: (
      <Suspense fallback={<Loader />}>
        <Presort />
      </Suspense>
    ),
    icon: <PresortIcon width={24} height={24} />,
    reload: true
  },
  {
    path: '/move',
    menuName: 'Move',
    element: (
      <Suspense fallback={<Loader />}>
        <Move />
      </Suspense>
    ),
    icon: <CompareArrowsIcon />
  },
  {
    path: '/checkin',
    menuName: 'Check In',
    element: (
      <Suspense fallback={<Loader />}>
        <Checkin />
      </Suspense>
    ),
    icon: <CheckinIcon width={24} height={24} />
  },
  {
    path: '/pick',
    menuName: 'Pick',
    element: (
      <Suspense fallback={<Loader />}>
        <Pick />
      </Suspense>
    ),
    icon: <PickIcon width={24} height={24} />,
    reload: true
  },
  {
    path: '/pack',
    menuName: 'Pack',
    element: (
      <Suspense fallback={<Loader />}>
        <Pack />
      </Suspense>
    ),
    icon: <PackIcon width={24} height={24} />
  },
  {
    path: '/ship',
    menuName: 'Ship',
    element: (
      <Suspense fallback={<Loader />}>
        <Ship />
      </Suspense>
    ),
    icon: <ShipIcon width={24} height={24} />
  },
  {
    path: '/containerize',
    menuName: 'Containerize',
    element: (
      <Suspense fallback={<Loader />}>
        <Containerize />
      </Suspense>
    ),
    icon: <ContainerizeIcon width={24} height={24} />,
    reload: true
  },
  {
    path: '/units',
    menuName: 'Units',
    element: (
      <Suspense fallback={<Loader />}>
        <Units />
      </Suspense>
    ),
    icon: <ViewListIcon width={24} height={24} />
  },
  {
    path: '/orders',
    menuName: 'Orders',
    element: (
      <Suspense fallback={<Loader />}>
        <Orders />
      </Suspense>
    ),
    icon: <ShoppingCartIcon width={24} height={24} />
  },
  {
    path: '/take-back',
    menuName: 'Take Back Orders',
    element: <Takeback />,
    icon: <TakebackIcon width={24} height={24} />
  },
  {
    path: '/inbound-order',
    menuName: 'Inbound Order',
    element: (
      <Suspense fallback={<Loader />}>
        <InboudOrder />
      </Suspense>
    ),
    icon: <InboundShoppingCartIcons width={24} height={24} />
  },
  {
    path: '/return-label',
    menuName: 'Return Label',
    element: (
      <Suspense fallback={<Loader />}>
        <ReturnLabel />
      </Suspense>
    ),
    icon: <ReturnLabelIcon width={24} height={24} />
  }
];

export const getMenuRoutes = (routesToExclude: string[]) =>
  menuRoutes.filter(
    (route) =>
      route.path === '/' ||
      (route.menuName && !routesToExclude.includes(route.menuName.toLowerCase()))
  );

const graphiqlPath = {
  path: '/graphiql',
  element: <GraphiQl />
};

export const getLayoutRoutes = (routesToExclude: string[]) => [
  {
    element: <DrawerMenuLayout />,
    children: getMenuRoutes(routesToExclude)
  },
  ...(process.env.NODE_ENV === 'development' ? [graphiqlPath] : [])
];

export const routesToExclude: string[] = ['returns'];

export const layoutRoutes = [
  {
    element: <DrawerMenuLayout />,
    children: [...menuRoutes]
  }
];
