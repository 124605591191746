import { ApolloProvider } from '@arrive/providers/ApolloProvider';
import { AuthenticationProvider } from '@arrive/providers/AuthenticationProvider';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import * as serviceWorker from './serviceWorker';
import { auth0Config } from './config';
import App from './App';

render(
  <StrictMode>
    <Auth0Provider {...auth0Config} cacheLocation={'localstorage'}>
      <AuthenticationProvider>
        <ApolloProvider>
          <App />
        </ApolloProvider>
      </AuthenticationProvider>
    </Auth0Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
