import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorage } from 'usehooks-ts';

export function useLogout() {
  const { logout: auth0Logout } = useAuth0();
  const [, setAccessToken] = useLocalStorage<string | undefined>('access_token', undefined);

  const logout = () => {
    setAccessToken(undefined);
    auth0Logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECTURI ?? window.location.origin });
  };

  return logout;
}
